import "./sentry";

import * as Bananas from "bananas-commerce-admin";
import React from "react";
import { createRoot } from "react-dom/client";

import { createTheme } from "@mui/material/styles";

import { getEnv } from "./env";
import Logo from "./icons/arkivet.svg";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Bananas.App
      basename={getEnv("BASE_URL")}
      api={getEnv("API_SCHEMA_URL")}
      navigation={{
        ...Bananas.pos.navigation,
        ...Bananas.fulfillment.navigation,
        ...Bananas.report.navigation,
      }}
      extensions={[
        Bananas.bananas.router,
        Bananas.pos.router,
        Bananas.fulfillment.router,
        Bananas.report.router,
      ]}
      contrib={{
        ...Bananas.fulfillment.contrib.orders,
        ...Bananas.fulfillment.contrib.returns,
      }}
      logo={Logo}
      theme={createTheme({
        palette: {
          primary: {
            main: "#7b61ff",
          },
          secondary: {
            main: "#7b61ff",
          },
        },
      })}
    />
  </React.StrictMode>
);
