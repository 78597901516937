import type { defaults } from "./defaults";

type __env = {
  [K in keyof typeof defaults]: (typeof defaults)[K] extends undefined
    ? string | undefined
    : string;
};

/**
 * An object containing all runtime inserted environment variables. **Beware!**
 * these may be undefined, a weird nginx ssi string or empty and are not meant
 * for use directly. Use the {@link getEnv} function to get a type safe and
 * hopefully correct and defined environment variable.
 */
declare const __env_runtime: __env;

/**
 * An object containing all statically inserted environment variables, falling
 * back to their default or undefined if undefined when building. **Beware!**
 * the same warning applies here, see {@link __env_runtime}.
 */
declare const __env_static: __env;

/**
 * Gets the environment variable and cleans it from any nginx ssi template
 * values or other invalid or uncleaned values.
 */
export function getEnv<K extends keyof typeof defaults>(
  name: K
): (typeof __env_runtime)[K] {
  const value = __env_runtime[name];
  return (
    (value && ![`<!--# echo var="${name}" -->`, "(none)", ""].includes(value)
      ? value
      : undefined) ?? __env_static[name]
  );
}

/**
 * Define {@link getEnv} globally just as a helper function useful when
 * debugging environment variables.
 */
globalThis.getEnv = getEnv;
