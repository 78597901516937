import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";

import { getEnv } from "./env";

const SENTRY_DSN = getEnv("SENTRY_DSN");
const SENTRY_ENVIRONMENT = getEnv("SENTRY_ENVIRONMENT");

if (SENTRY_DSN !== undefined) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
